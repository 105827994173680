<template>
  <el-dialog
      :visible.sync="dialogVisible"
      top="2%"
      width="1000px"
      title="选择题目"
      :center="true"
      :before-close="doClose"
  >
    <section class="CourseTables">
      <div class="operationControl" style="border:0">
        <div class="searchbox" style="margin-bottom: 5px">
          <div title="题目内容" class="searchboxItem ci-full">
            <span class="itemLabel">题目内容:</span>
            <el-input
                v-model="questionName"
                type="text"
                size="small"
                clearable
                placeholder="请输入题目内容"
            />
          </div>
          <div title="题目类型" class="searchboxItem ci-full">
            <span class="itemLabel">题目类型:</span>
            <el-select
                v-model="questionType"
                placeholder="请选择题目类型"
                size="small"
                clearable
            >
              <el-option
                  v-for="item in QuestionTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div title="知识点" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 5em;">知识点:</span>
              <el-cascader
                  v-model="knowledgeId"
                  :options="knowledgeIdList"
                  :props="{
                      checkStrictly: true,
                      emitPath: false,
                      label:'knowledgeName',
                      value:'knowledgeId',
                      children:'children'
                    }"
                  filterable
                  clearable
                  placeholder="请选择知识点"
                  size="small">
              </el-cascader>
            </div>
          <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              :header-cell-style="tableHeader"
              size="small"
              tooltip-effect="dark"
              row-key="questionId"
              style="width: 100%"
              @selection-change="selectionChange"
              stripe
              border
          >
            <el-table-column
                type="selection"
                width="50px"
                align="center"
                :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
                label="题目内容"
                align="center"
                prop="questionName"
            >
              <template slot-scope="scope">
                <span class="richText" v-html="scope.row.questionName"></span>
              </template>
            </el-table-column>
            <el-table-column
                label="题目类别"
                align="center"
                prop="questionType"
            >
              <template slot-scope="scope">
                {{
                  $setDictionary("TOOL_QUESTION_TYPE", scope.row.questionType)
                }}
              </template>
            </el-table-column>
            <el-table-column
                label="学员是否可见"
                align="center"
                prop="userShow"
            >
              <template slot-scope="scope">
                {{
                  scope.row.userShow?'可见':'不可见'
                }}
              </template>
            </el-table-column>
            <el-table-column
                label="题目难度"
                align="center"
                prop="difficultyLevel"
            >
              <template slot-scope="scope">
                {{
                  $setDictionary("EXAM_QUESTION_DIFFICULTY", scope.row.difficultyLevel)
                }}
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>

          <div class="scoreSet">
            <el-form ref="formData" :model="formData" label-width="138px" size="small" :rules="rules">
              <el-row>
                <el-col span="12" v-if="multipleTable.filter(item=>item.questionType == '10').length>0">
                  <el-form-item label="单选题每题" prop="scoreSingleChoiceQuestions" class="form-item">
                    <el-input-number v-model="formData.scoreSingleChoiceQuestions" :min="0" :max="100" :step="1" precision="2" label="请输入单选题每题分数" :controls="false"></el-input-number>&nbsp;&nbsp;分
                  </el-form-item>
                </el-col>
                <el-col span="12" v-if="multipleTable.filter(item=>item.questionType == '20').length>0">
                  <el-form-item label="多选题每题" prop="scoreMultipleChoiceQuestions" class="form-item">
                    <el-input-number v-model="formData.scoreMultipleChoiceQuestions" :min="0" :max="100" :step="1" precision="2" label="请输入多选题每题分数" :controls="false"></el-input-number>&nbsp;&nbsp;分
                  </el-form-item>
                </el-col>
                <el-col span="12" v-if="multipleTable.filter(item=>item.questionType == '30').length>0">
                  <el-form-item label="判断题每题" prop="scoreJudge" class="form-item">
                    <el-input-number v-model="formData.scoreJudge" :min="0" :max="100" :step="1" precision="2" label="请输入判断题每题分数" :controls="false"></el-input-number>&nbsp;&nbsp;分
                  </el-form-item>
                </el-col>
                <el-col span="12" v-if="multipleTable.filter(item=>item.questionType == '40').length>0">
                  <el-form-item label="填空题每题" prop="scoreCompletion" class="form-item">
                    <el-input-number v-model="formData.scoreCompletion" :min="0" :max="100" :step="1" precision="2" label="请输入填空题每题分数" :controls="false"></el-input-number>&nbsp;&nbsp;分
                  </el-form-item>
                </el-col>
                <el-col span="12" v-if="multipleTable.filter(item=>item.questionType == '50').length>0">
                  <el-form-item label="简答题每题" prop="scoreShortAnswer" class="form-item">
                    <el-input-number v-model="formData.scoreShortAnswer" :min="0" :max="100" :step="1" precision="2" label="请输入简答题每题分数" :controls="false"></el-input-number>&nbsp;&nbsp;分
                  </el-form-item>
                </el-col>
                <el-col span="12" v-if="multipleTable.filter(item=>item.questionType == '60').length>0">
                  <el-form-item label="计算题每题" prop="scoreCalculationQuestions" class="form-item">
                    <el-input-number v-model="formData.scoreCalculationQuestions" :min="0" :max="100" :step="1" precision="2" label="请输入计算题每题分数" :controls="false"></el-input-number>&nbsp;&nbsp;分
                  </el-form-item>
                </el-col>
                <el-col span="12" v-if="multipleTable.filter(item=>item.questionType == '64').length>0">
                  <el-form-item label="论述题每题" prop="scoreEssayQuestion" class="form-item">
                    <el-input-number v-model="formData.scoreEssayQuestion" :min="0" :max="100" :step="1" precision="2" label="请输入论述题每题分数" :controls="false"></el-input-number>&nbsp;&nbsp;分
                  </el-form-item>
                </el-col>
                <el-col span="12" v-if="multipleTable.filter(item=>item.questionType == '68').length>0">
                  <el-form-item label="案例分析题每题" prop="scoreCaseAnalysisQuestionsReal" class="form-item">
                    <el-input-number v-model="formData.scoreCaseAnalysisQuestionsReal" :min="0" :max="100" :step="1" precision="2" label="请输入案例分析题每题分数" :controls="false"></el-input-number>&nbsp;&nbsp;分
                  </el-form-item>
                </el-col>
                <el-col span="12" v-if="multipleTable.filter(item=>item.questionType == '70').length>0">
                  <el-form-item label="组合题(复合题型)每小题" prop="scoreCaseAnalysisQuestions" class="form-item">
                    <el-input-number v-model="formData.scoreCaseAnalysisQuestions" :min="0" :max="100" :step="1" precision="2" label="请输入组合题(复合题型)每小题分数" :controls="false"></el-input-number>&nbsp;&nbsp;分
                  </el-form-item>
                </el-col>
                <el-col span="12" v-if="multipleTable.filter(item=>item.questionType == '80').length>0">
                  <el-form-item label="组合题(单一题型)分析每小题" prop="scoreCombinationQuestion" class="form-item">
                    <el-input-number v-model="formData.scoreCombinationQuestion" :min="0" :max="100" :step="1" precision="2" label="请输入组合题(单一题型)分析每小题分数" :controls="false"></el-input-number>&nbsp;&nbsp;分
                  </el-form-item>
                </el-col>
              </el-row>








            </el-form>

          </div>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <span
          class="dialog-footer"
          style="margin-top:10px;display:flex;justify-content: center;"
      >
        <div>
          <el-button class="bgc-bv" @click="doClose">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确定</el-button>
        </div>
      </span>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "selectQuestion",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      partitionId: '',
      bankId: '',
      paperId: '',

      questionName:'',
      questionType:'',
      knowledgeId:'',

      QuestionTypeList: [],


      multipleTable: [],
      dialogVisible: false,

      start: false,

      formData: {
        scoreSingleChoiceQuestions: undefined,//单选每题分数
        scoreMultipleChoiceQuestions: undefined,//多选每题分数
        scoreJudge: undefined,//判断每题分数
        scoreCompletion: undefined,//填空每题分数
        scoreShortAnswer: undefined,//简答每题分数
        scoreCalculationQuestions: undefined,//计算每题分数
        scoreEssayQuestion: undefined, //论述题每题分数
        scoreCaseAnalysisQuestionsReal: undefined, //案例分析题每题分数
        scoreCaseAnalysisQuestions: undefined,//组合题(复合题型)每小题分数
        scoreCombinationQuestion: undefined,//组合题(单一题型)每小题分数
      },


      rules: {
        scoreSingleChoiceQuestions:[{required: true, message: '单选题每题分数不能为空！', trigger: 'blur'}],
        scoreMultipleChoiceQuestions:[{required: true, message: '多选题每题分数不能为空！', trigger: 'blur'}],
        scoreJudge:[{required: true, message: '判断题每题分数不能为空！', trigger: 'blur'}],
        scoreCompletion:[{required: true, message: '填空题每题分数不能为空！', trigger: 'blur'}],
        scoreShortAnswer:[{required: true, message: '简答题每题分数不能为空！', trigger: 'blur'}],
        scoreCalculationQuestions:[{required: true, message: '计算题每题分数不能为空！', trigger: 'blur'}],
        scoreEssayQuestion:[{required: true, message: '论述题每题分数不能为空！', trigger: 'blur'}],
        scoreCaseAnalysisQuestionsReal:[{required: true, message: '案例分析题每题分数不能为空！', trigger: 'blur'}],
        scoreCaseAnalysisQuestions:[{required: true, message: '组合题(复合题型)每小题分数不能为空！', trigger: 'blur'}],
        scoreCombinationQuestion:[{required: true, message: '组合题(单一题型)分析每小题分数不能为空！', trigger: 'blur'}],

      },

      knowledgeIdList: []
    };
  },
  computed: {
  },
  created() {
    this.getQuestionTypeList();
  },
  updated() {
  },
  methods: {
    //获取知识点下拉数据
    getKnowledgeList() {
      this.$post("/biz/exam/bank/knowledge/selectable/tree", {"bankId": this.bankId},3000,true,6)
          .then((ret) => {
            if (ret.status == 0) {
              this.knowledgeIdList = this.delNullData(ret.data)
            }
          })
          .catch((err) => {
            return;
          });
    },
    // 去除空数据
    delNullData(list){
      list.forEach((e,i)=>{
        if(e.children.length == 0){
          e.children = undefined
        }else{
          this.delNullData(e.children)
        }
      })
      return list
    },
    // 表格多选
    selectionChange(val){
      this.multipleTable = val
    },
    //获取测评分类标识--码值
    getQuestionTypeList() {
      const QuestionType = this.$setDictionary("TOOL_QUESTION_TYPE", "list");
      for (const key in QuestionType) {
        this.QuestionTypeList.push({
          value: key,
          label: QuestionType[key],
        });
      }
    },
    showPopUp(partitionId,bankId,paperId) {
      this.dialogVisible = true;

      this.partitionId = partitionId;
      this.bankId = bankId;
      this.paperId = paperId;

      this.start = true;
      if (this.start) {
        this.getKnowledgeList();
        this.getData();
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperId: this.paperId || "",
        bankId: this.bankId || "",
      };
      if (this.questionName) {
        params.questionName = this.questionName;
      }
      if (this.questionType) {
        params.questionType = this.questionType;
      }
      if (this.knowledgeId) {
        params.knowledgeId = this.knowledgeId;
      }
      
      // console.log(params)
      if (this.start) {
        this.doFetch({
          url: "/biz/exam/bank/paper/question/selectBankQuestion",
          params,
          pageNum,
        },true,6);
      }
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      if(this.multipleTable.length == 0){
        this.$message.error('请至少选择一道题！')
        return
      }

      this.$refs.formData.validate((valid) => {
        if (valid) {
          let scores = []
          // 检验选中题目是否含有相应题目类型，有就入参
          if(this.multipleTable.filter(item=>item.questionType == '10').length>0){
            scores.push({
              "questionType": "10",
              "score": this.formData.scoreSingleChoiceQuestions
            })
          }
          if(this.multipleTable.filter(item=>item.questionType == '20').length>0){
            scores.push({
              "questionType": "20",
              "score": this.formData.scoreMultipleChoiceQuestions
            })
          }
          if(this.multipleTable.filter(item=>item.questionType == '30').length>0){
            scores.push({
              "questionType": "30",
              "score": this.formData.scoreJudge
            })
          }
          if(this.multipleTable.filter(item=>item.questionType == '40').length>0){
            scores.push({
              "questionType": "40",
              "score": this.formData.scoreCompletion
            })
          }
          if(this.multipleTable.filter(item=>item.questionType == '50').length>0){
            scores.push({
              "questionType": "50",
              "score": this.formData.scoreShortAnswer
            })
          }
          if(this.multipleTable.filter(item=>item.questionType == '60').length>0){
            scores.push({
              "questionType": "60",
              "score": this.formData.scoreCalculationQuestions
            })
          }
          if(this.multipleTable.filter(item=>item.questionType == '64').length>0){
            scores.push({
              "questionType": "64",
              "score": this.formData.scoreEssayQuestion
            })
          }
          if(this.multipleTable.filter(item=>item.questionType == '68').length>0){
            scores.push({
              "questionType": "68",
              "score": this.formData.scoreCaseAnalysisQuestionsReal
            })
          }
          if(this.multipleTable.filter(item=>item.questionType == '70').length>0){
            scores.push({
              "questionType": "70",
              "score": this.formData.scoreCaseAnalysisQuestions
            })
          }
          if(this.multipleTable.filter(item=>item.questionType == '80').length>0){
            scores.push({
              "questionType": "80",
              "score": this.formData.scoreCombinationQuestion
            })
          }
          let questionIds = []
          this.multipleTable.forEach((e,i)=>{
            questionIds.push(e.questionId)
          })
          let params = {
            "paperId": this.paperId,
            "partitionId": this.partitionId,
            "questionIds": questionIds,
            "scores": scores
          }
          // console.log(params)
          this.$post("/biz/exam/bank/paper/question/savePaperQuestion", params,3000,true,6)
              .then((ret) => {
                if (ret.status == 0) {
                  this.$message({
                    message: ret.message,
                    type: "success",
                  });
                  // 父组件重新获取数据
                  this.$parent.getPaperInfo()
                  this.doClose()
                }
              })
              .catch((err) => {
                return;
              });
        }

      })
    },
    doClose() {

      this.questionName = ''
      this.questionType = ''
      this.knowledgeId = ''
      
      
      this.$refs.multipleTable.clearSelection();
      this.$refs.formData.resetFields();
      this.multipleTable = []
      this.formData = {
        scoreSingleChoiceQuestions: undefined,//单选每题分数
        scoreMultipleChoiceQuestions: undefined,//多选每题分数
        scoreJudge: undefined,//判断每题分数
        scoreCompletion: undefined,//填空每题分数
        scoreShortAnswer: undefined,//简答每题分数
        scoreCalculationQuestions: undefined,//计算每题分数
        scoreEssayQuestion: undefined, //论述题每题分数
        scoreCaseAnalysisQuestionsReal: undefined, //案例分析题每题分数
        scoreCaseAnalysisQuestions: undefined,//案例分析每小题分数
        scoreCombinationQuestion: undefined,//组合分析每小题分数
      }

      this.dialogVisible = false;
    },

  },
};
</script>
<style lang="less" scoped>
/deep/.el-pagination{
  padding: 10px 5px;
}

.scoreSet{
  margin-top: 20px;
}
.CourseTables {
  .el-radio {
    .el-radio__label {
      display: none;
    }
    .el-radio__inner {
      width: 18px;
      height: 18px;
      border-color: #777;
    }
    .el-radio__inner:hover {
      border-color: #5c6be8;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
  .Batch {
    padding: 1rem 1rem;
    .BatchBox {
      display: flex;
      height: 120px;
      border: 1px solid #ebeef5;
      border-radius: 5px;
      .BatchBoxFirst {
        width: 260px;
        background: #f2f7fd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .BatchBoxSecond {
        width: 100%;
        .BatchforBox {
          display: flex;
          flex-wrap: wrap;
          .BatchforFlex {
            display: flex;
            align-items: center;
            padding: 5px 20px;
            cursor: pointer;
            .BatchImgBox {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
